import React from 'react';
import { Button } from '@mui/material';

const ClearButton = ({ onClick }) => (
	<Button variant="contained" size="small" color="secondary" onClick={onClick}>
		Clear
	</Button>
);

export default ClearButton;
