import { Box, Button, Card, CardContent, CardMedia, Chip, IconButton, Modal, Tooltip, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { SouthAfricanRand } from "../../utils/helpers";
import { round } from "lodash";
import { CustomerCategory } from "../../utils/constants";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import cssVariables from "../../utils/cssVariables";

const DesktopGridItem = ({
	availabilityImage,
	applicationPositionImage,
	colourForAvailability,
	handleCloseModal,
	handleOpenModal,
	colourForQuality,
	customerCategory,
	fitmentImage,
	handleAddToCartClick,
	modalContent,
	openModal,
	qualityImage,
	sanitizedData,
	vendorImage,
	warranty,
	warrantyText,
	showVehicleSpecificInformation,
	vendorDescription,
	invoicePrice,
	price,
	image,
	title,
	vendor,
	fitment,
	sku,
	availability,
	quality
}) => {
	return (
		<Card raised sx={{ position: 'relative', height: '100%', display: 'flex', flexDirection: 'column', minWidth: "250px", maxWidth: "250px" }}>
			<Box sx={{ position: 'absolute', top: 16, left: 16, zIndex: 10, width: 40, height: 40 }}>
				{vendorImage ? (
					<Tooltip title={vendorDescription}>
						<img src={vendorImage} alt={vendor} style={{ height: '100%', width: '100%' }} />
					</Tooltip>
				) : (
					<Box sx={{ height: '100%', width: '100%' }} />
				)}
			</Box>

			<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px', backgroundColor: '#f9f9f9' }}>
				<CardMedia component="img"
				           image={image}
				           alt={title}
				           sx={{
							   maxHeight: '170px',
					           maxWidth: '130px',
				           }}
				/>
			</Box>

			<Box sx={{ position: 'absolute', top: 16, right: 16, width: 40, height: 40 }}>
				{fitment ? (
					<IconButton onClick={() => handleOpenModal(<img src={fitmentImage} alt="Fitment" style={{ width: '100%' }} />)}>
						<img src={fitmentImage} alt="Fitment" style={{ height: '100%', width: '100%' }} />
					</IconButton>
				) : (
					<Box sx={{ height: '100%', width: '100%' }} />
				)}
			</Box>

			<CardContent sx={{ flexGrow: 1 }}>
				<a href={`/product/${sku}`} style={{textDecoration: 'none', color: 'inherit'}}>
					<Typography
						variant="subtitle1"
						sx={{
							fontSize: cssVariables.fontSizeXS,
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							display: '-webkit-box',
							WebkitLineClamp: 2,
							WebkitBoxOrient: 'vertical',
							lineHeight: cssVariables.lineHeightXS,
							maxHeight: '2.8em',
							minHeight: '2.8em',
						}}
					>
						{title}
					</Typography>
				</a>


				<Box display="flex" alignItems="center" mb={2} justifyContent="flex-start" sx={{ gap: '4px' }}>
					<Chip
						size="small"
						label={availability}
						color={colourForAvailability || 'default'}
						onClick={() => handleOpenModal(
							<img
								src={availabilityImage}
								alt="Availability"
								style={{ width: '100%', height: 'auto', maxWidth: '600px', maxHeight: '400px' }}
							/>
						)}
					/>
					{!!warranty && !!warrantyText && (
						<Tooltip title={warrantyText}>
							<Chip
								size="small"
								label={warranty}
								color="error"
								onClick={() => handleOpenModal(warrantyText)}
							/>
						</Tooltip>
					)}
					{quality && (
						<Chip
							size="small"
							label={quality}
							color={colourForQuality || 'default'}
							onClick={() => handleOpenModal(
								<img
									src={qualityImage}
									alt="Quality"
									style={{ width: '100%', height: 'auto', maxWidth: '600px', maxHeight: '400px' }}
								/>
							)}
						/>
					)}
					<IconButton onClick={() => handleOpenModal(<div dangerouslySetInnerHTML={sanitizedData()} />)}>
						<InfoIcon sx={{ cursor: 'pointer', fontSize: 20 }} />
					</IconButton>
				</Box>

				<Typography variant="body1" sx={{ fontWeight: "bold" }}>
					{SouthAfricanRand.format(round(price, 2))}
				</Typography>

				{invoicePrice > 0 && invoicePrice !== price && (
					<Typography variant="caption" color="textSecondary">
						Invoice price: <strong>{SouthAfricanRand.format(round(invoicePrice, 2))}</strong>
					</Typography>
				)}
				{customerCategory !== CustomerCategory.Retail && (
					<Typography variant="caption" color="textSecondary" sx={{ fontSize: '0.75rem' }}>
						Ex. VAT
					</Typography>
				)}
			</CardContent>

			<Box sx={{ p: 2 }}>
				<Button
					size="small"
					variant="contained"
					color="error"
					fullWidth
					onClick={handleAddToCartClick}
				>
					Add to cart
				</Button>
			</Box>

			<Modal open={openModal} onClose={handleCloseModal}>
				<Box sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: 400,
					maxHeight: '80vh',
					bgcolor: 'background.paper',
					borderRadius: '8px',
					boxShadow: 24,
					p: 4,
					overflowY: 'auto',
				}}>
					<IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={handleCloseModal}>
						<CloseIcon />
					</IconButton>
					{modalContent}
				</Box>
			</Modal>
		</Card>
	)
}

export default DesktopGridItem;