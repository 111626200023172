import React, { Fragment, useEffect, useState } from "react";
import Product from "../../components/Product";
import { useParams } from "react-router-dom";
import { Backdrop, CircularProgress, Container } from "@mui/material";
import { removeAuthCookies } from "../../api/authentication";
import { AccountType, ApiCallStatus } from "../../utils/constants";
import { fetchProductBySku } from "../PageFunctions/productFunctions";
import { fetchProductPrice } from "../PageFunctions/priceFunctions";
import { useAuth0 } from "@auth0/auth0-react";

const clearCookies = async () => {
	await removeAuthCookies();
};

const ProductPage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const {getAccessTokenSilently, isAuthenticated, isLoading} = useAuth0();
	const { productSku} = useParams();
	const [productData, setProductData] = useState(null);
	const [pricingData, setPricingData] = useState(null);

	const [productFetchCallStatus, setProductFetchCallStatus] = useState(ApiCallStatus.NotStarted);
	const [priceFetchCallStatus, setPriceFetchCallStatus] = useState(ApiCallStatus.NotStarted);

	const clearCookiesAsync = async () => {
		await clearCookies();
	};

	const fetchProductBySkuAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		if (productFetchCallStatus === ApiCallStatus.NotStarted) {
			await fetchProductBySku({
				auth0Token,
				productSku,
				setProductData,
				setStatusInProgress: () => setProductFetchCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setProductFetchCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setProductFetchCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setProductFetchCallStatus(ApiCallStatus.Error)
			});
		}
	};

	const fetchProductPriceAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		if (priceFetchCallStatus === ApiCallStatus.NotStarted) {
			await fetchProductPrice({
				auth0Token,
				productSku,
				setPricingData,
				setStatusInProgress: () => setPriceFetchCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setPriceFetchCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setPriceFetchCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setPriceFetchCallStatus(ApiCallStatus.Error)
			});
		}
	};

	const unauthorizedCalls = async () => {
		await Promise.all([
			fetchProductBySkuAsync()
		]);
	};

	const authorizedCalls = async () => {
		await Promise.all([

		]);
	};

	useEffect(() => {
		if (genericDataLoaded === true && isLoading === false) {
			if (isAuthenticated === false) {
				clearCookiesAsync();
				unauthorizedCalls();
			} else {
				authorizedCalls();
				unauthorizedCalls();
			}
		}
	}, [genericDataLoaded, isAuthenticated, isLoading]);

	useEffect(() => {
		if (productFetchCallStatus === ApiCallStatus.Succeeded) {
			fetchProductPriceAsync();
		}
	}, [productFetchCallStatus]);

	const allLoadingStates = [
		productFetchCallStatus,
		priceFetchCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || genericDataLoaded === false || isLoading === true;

	return (
		<Container maxWidth="lg">
			<Product
				product={productData}
				pricingData={pricingData}
				accountType={genericPageContent?.accountType ?? AccountType.Retail}
				loading={pageIsLoading}
			/>
			<Backdrop
				sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Container>
	);
};
export default ProductPage;
