import React, { useEffect, useState } from "react";
import { SouthAfricanRand } from "../../utils/helpers";
import {
	LogisticsMethod,
	OrderNumberPrefix,
	OrderPaymentStatus,
	PaymentConfirmationMessage,
	PaymentType
} from "../../utils/constants";
import { useMediaQuery } from "react-responsive";
import { find, sum } from "lodash";
import moment from "moment";
import { Box, Divider, Grid, Icon, Paper, Typography } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Image from "../Image";

const OrderConfirmation = ({
	orderData
}) => {
	const [confirmationHeading, setConfirmationHeading] = useState(null);
	const [confirmationMessage, setConfirmationMessage] = useState(null);

	useEffect(() => {

		if (orderData?.orderPaymentStatus === OrderPaymentStatus.PendingPayment) {
			setConfirmationHeading("Thank you for your purchase");
			setConfirmationMessage(PaymentConfirmationMessage.MessagePending);
		} else if (orderData?.orderPaymentStatus === OrderPaymentStatus.PartiallyPaid) {
			setConfirmationHeading("Thank you for your purchase");
			setConfirmationMessage(PaymentConfirmationMessage.MessagePartialComplete);
		} else if (orderData?.orderPaymentStatus === OrderPaymentStatus.Paid) {
			setConfirmationHeading("Thank you for your purchase");
			setConfirmationMessage(PaymentConfirmationMessage.MessageComplete);
		}

		if (!orderData) {
			setConfirmationHeading(PaymentConfirmationMessage.HeadingFailed);
			setConfirmationMessage(PaymentConfirmationMessage.MessageFailed);
		}
	}, [orderData]);

	return (
		<Box sx={{ minHeight: '100vh', padding: '2rem', backgroundColor: '#f4f4f9' }}>
			{!!orderData && (
				<Grid container justifyContent="center" spacing={4}>
					<Grid item xs={12} md={6} sx={{ verticalAlign: "middle" }}>
						<Paper elevation={3} sx={{ padding: '2rem', textAlign: 'center' }}>
							<CheckCircleIcon color="success" fontSize="large" />
							<Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: '1rem' }}>
								{confirmationHeading}
							</Typography>
							<Typography variant="body2" sx={{ marginTop: '1rem', color: '#555' }}>
								{confirmationMessage}
							</Typography>
							{orderData?.payments[0]?.paymentTypeId === PaymentType.EFT && (
								<>
									{!!orderData?.orderNumber && (
										<Typography variant="body1" sx={{ marginTop: '1.5rem' }}>
											{`Reference: ${orderData.orderNumber}`}
										</Typography>
									)}
									<Typography variant="body2" sx={{ marginTop: '1rem', color: '#555' }}>
										Your order is almost complete! We are just waiting for your EFT payment.
										<br />
										To make the payment, please use one of the following EFT details:
									</Typography>

									<Divider sx={{ margin: '1.5rem 0' }} />
									<Typography variant="body2" sx={{ color: '#555' }}>
										<b>Standard Bank Benoni</b>
										<br />
										Account No: 020971117
										<br />
										Branch Code: 013042
									</Typography>
									<Typography variant="body2" sx={{ marginTop: '1rem', color: '#555' }}>
										<b>Nedbank Business East Rand</b>
										<br />
										Account No: 1207564400
										<br />
										Code: 198765
									</Typography>
								</>
							)}

							{orderData?.payments[0]?.paymentTypeId === PaymentType.CreditCardOnCollection && (
								<>
									{!!orderData?.orderNumber && (
										<Typography variant="body1" sx={{ marginTop: '1.5rem' }}>
											{`Reference: ${OrderNumberPrefix}${orderData.orderNumber}`}
										</Typography>
									)}
									<Typography variant="body2" sx={{ marginTop: '1rem', color: '#555' }}>
										Your order has been received.
									</Typography>
									<Divider sx={{ margin: '1.5rem 0' }} />
									<Typography variant="body2" sx={{ color: '#555' }}>
										<b>Collection Address</b>
										<br />
										Argus Motor Company
										<br />
										28 Great North Road
										<br />
										Brentwood Park
										<br />
										Benoni, 1501
									</Typography>
									<Typography variant="body2" sx={{ marginTop: '1rem', color: '#555' }}>
										<b>Collection Times</b>
										<br />
										Mon - Thurs 8:00am – 4:30pm
										<br />
										Fri 8:00am – 1:30pm
									</Typography>
									<Typography variant="body2" sx={{ marginTop: '1.5rem', color: '#555' }}>
										Please note that we will only process your order once you arrive and make payment.
										Additional time may be required for order processing.
									</Typography>
								</>
							)}
						</Paper>
					</Grid>

					{/* Order Summary */}
					<Grid item xs={12} md={6}>
						<Paper elevation={3} sx={{ padding: '2rem' }}>
							<Typography variant="body2" sx={{ marginBottom: '1rem' }}>
								Order Summary
							</Typography>
							<Divider sx={{ marginBottom: '1.5rem' }} />

							{/* Order Details */}
							<Grid container spacing={2} textAlign="center">
								<Grid item xs={12} sm={4}>
									<Typography variant="caption" color="textSecondary">Date</Typography>
									<Typography variant="body2">{moment(orderData.dateCreated).format('DD MMM YYYY')}</Typography>
								</Grid>
								<Grid item xs={12} sm={4}>
									<Typography variant="caption" color="textSecondary">Order #</Typography>
									<Typography variant="body2">{orderData.orderNumber}</Typography>
								</Grid>
								<Grid item xs={12} sm={4}>
									<Typography variant="caption" color="textSecondary">Shipping Method</Typography>
									<Typography variant="body2">
										{orderData.logisticsMethod === LogisticsMethod.Collection ? "Collection" : "Ship"}
									</Typography>
								</Grid>
							</Grid>
							<Divider sx={{ margin: '1.5rem 0' }} />

							{/* Order Items */}
							{orderData.orderItems.map((item, index) => {
								const product = orderData.products.find(x => x.sku === item.sku);
								if (!product) return null;

								return (
									<Grid container key={index} spacing={2} sx={{ marginBottom: '1.5rem' }}>
										<Grid item xs={12} sm={3}>
											<Image src={product.image} alt={product.description} sx={{ width: '100%' }} />
										</Grid>
										<Grid item xs={12} sm={6}>
											<Typography variant="body2" fontWeight="bold">{product.description}</Typography>
											<Typography variant="caption" color="textSecondary">SKU: {product.sku}</Typography>
											<br />
											<Typography variant="caption">Qty: {item.quantity}</Typography>
										</Grid>
										<Grid item xs={12} sm={3} textAlign="right">
											<Typography variant="body2">{SouthAfricanRand.format(item.priceSnapshot)}</Typography>
										</Grid>
									</Grid>
								);
							})}

							{/* Order Totals */}
							<Divider sx={{ marginBottom: '1rem' }} />
							<Grid container spacing={2}>
								<Grid item xs={8}>
									<Typography variant="caption" color="textSecondary">Subtotal</Typography>
								</Grid>
								<Grid item xs={4} textAlign="right">
									<Typography variant="caption" color="textSecondary">
										{SouthAfricanRand.format(orderData.orderItems.reduce((sum, item) => sum + item.priceSnapshot * item.quantity, 0))}
									</Typography>
								</Grid>
							</Grid>

							{orderData.logisticsFee > 0 && (
								<Grid container spacing={2}>
									<Grid item xs={8}>
										<Typography variant="caption" color="textSecondary">Shipping</Typography>
									</Grid>
									<Grid item xs={4} textAlign="right">
										<Typography variant="caption" color="textSecondary">
											{SouthAfricanRand.format(orderData.logisticsFee)}
										</Typography>
									</Grid>
								</Grid>
							)}

							<Grid container spacing={2} sx={{ marginTop: '1rem' }}>
								<Grid item xs={8}>
									<Typography variant="caption" fontWeight="bold">Total</Typography>
								</Grid>
								<Grid item xs={4} textAlign="right">
									<Typography variant="caption" fontWeight="bold">
										{SouthAfricanRand.format(orderData.total)}
									</Typography>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			)}
		</Box>
	)
}

export default OrderConfirmation;