import React, { useEffect, useState } from "react";
import {
	OrderNumberPrefix,
	PaymentConfirmationMessage,
	PaymentStatus,
	PaymentType,
	ScreenSize
} from "../../utils/constants";
import "./style.css";
import { useCart } from "react-use-cart";
import { Box, Button, Container, Divider, Grid, Icon, Paper, Typography } from "@mui/material";
import useSystemHook from "../../hooks/SystemHook";

const PaymentConfirmation = ({paymentData, orderData, handleCloseClick}) => {
	const { screenSize } = useSystemHook();
	const {emptyCart} = useCart();
	const [heading, setHeading] = useState("");
	const [message, setMessage] = useState("");
	const [icon, setIcon] = useState("");
	const [iconColour, setIconColour] = useState("");

	useEffect(() => {
		if (paymentData) {
			if (paymentData.paymentStatusId === PaymentStatus.Complete) {
				setHeading(PaymentConfirmationMessage.HeadingComplete);
				setMessage(PaymentConfirmationMessage.MessageComplete);
				setIcon("check circle outline");
				setIconColour("green");
				emptyCart();
			}
			else if (paymentData.paymentStatusId === PaymentStatus.Pending) {
				setHeading(PaymentConfirmationMessage.HeadingPending);
				setMessage(PaymentConfirmationMessage.MessagePending);
				setIcon("check circle outline");
				setIconColour("yellow");
			}
			else if (paymentData.paymentStatusId === PaymentStatus.Failed) {
				setHeading(PaymentConfirmationMessage.HeadingFailed);
				setMessage(PaymentConfirmationMessage.MessageFailed);
				setIcon("times circle outline");
				setIconColour("red");
			}
		}
	}, [paymentData]);

	return (
		<>
			{screenSize === ScreenSize.Desktop &&
				<Box sx={{ minHeight: '100vh', backgroundColor: '#f7f7f7', padding: '2rem' }}>
					<Grid container justifyContent="center" spacing={4}>
						<Grid item xs={12} md={8}>
							<Paper elevation={3} sx={{ padding: '2rem', textAlign: 'center' }}>
								{/* Icon */}
								<Icon sx={{ fontSize: 100, color: iconColour }}>{icon}</Icon>
								<Typography variant="h4" sx={{ marginTop: '1rem', fontWeight: 'bold' }}>
									{heading}
								</Typography>
								<Typography variant="h6" sx={{ marginTop: '1rem', color: '#555' }}>
									{message}
								</Typography>

								{/* Conditional EFT Info */}
								{paymentData?.paymentTypeId === PaymentType.EFT && (
									<>
										{!!orderData?.orderNumber && (
											<Typography variant="body1" sx={{ marginTop: '1.5rem' }}>
												{`Reference: ${OrderNumberPrefix}${orderData.orderNumber}`}
											</Typography>
										)}
										<Typography variant="body2" sx={{ marginTop: '1rem', color: '#555' }}>
											Your order is almost complete! We are just waiting for your EFT payment.
											<br />
											To make the payment, please use one of the following EFT details:
										</Typography>

										<Divider sx={{ margin: '1.5rem 0' }} />
										<Typography variant="body2" sx={{ color: '#555' }}>
											<b>Standard Bank Benoni</b>
											<br />
											Account No: 020971117
											<br />
											Branch Code: 013042
										</Typography>
										<Typography variant="body2" sx={{ marginTop: '1rem', color: '#555' }}>
											<b>Nedbank Business East Rand</b>
											<br />
											Account No: 1207564400
											<br />
											Code: 198765
										</Typography>
									</>
								)}

								{/* Conditional Credit Card on Collection Info */}
								{paymentData?.paymentTypeId === PaymentType.CreditCardOnCollection && (
									<>
										{!!orderData?.orderNumber && (
											<Typography variant="body1" sx={{ marginTop: '1.5rem' }}>
												{`Reference: ${OrderNumberPrefix}${orderData.orderNumber}`}
											</Typography>
										)}
										<Typography variant="body2" sx={{ marginTop: '1rem', color: '#555' }}>
											Your order has been received.
										</Typography>
										<Divider sx={{ margin: '1.5rem 0' }} />
										<Typography variant="body2" sx={{ color: '#555' }}>
											<b>Collection Address</b>
											<br />
											Argus Motor Company
											<br />
											28 Great North Road
											<br />
											Brentwood Park
											<br />
											Benoni, 1501
										</Typography>
										<Typography variant="body2" sx={{ marginTop: '1rem', color: '#555' }}>
											<b>Collection Times</b>
											<br />
											Mon - Thurs 8:00am – 4:30pm
											<br />
											Fri 8:00am – 1:30pm
										</Typography>
										<Typography variant="body2" sx={{ marginTop: '1.5rem', color: '#555' }}>
											Please note that we will only process your order once you arrive and make payment.
											Additional time may be required for order processing.
										</Typography>
									</>
								)}

								{/* Close Button */}
								<Button
									size="small"
									variant="contained"
									color="error"
									sx={{ marginTop: '2rem' }}
									onClick={handleCloseClick}
								>
									Close
								</Button>
							</Paper>
						</Grid>
					</Grid>
				</Box>
			}
			{screenSize === ScreenSize.Mobile &&
				<Box sx={{ minHeight: '50vh', backgroundColor: '#f7f7f7', padding: '1rem' }}>
					<Container maxWidth="sm">
						<Paper elevation={3} sx={{ padding: '1.5rem', textAlign: 'center' }}>
							<Icon sx={{ fontSize: 70, color: iconColour, marginBottom: '1rem' }}>{icon}</Icon>
							<Typography variant="body2" sx={{ fontWeight: 'bold' }}>
								{heading}
							</Typography>
							<Typography variant="caption" sx={{ marginTop: '1rem', color: '#555' }}>
								{message}
							</Typography>

							{paymentData?.paymentTypeId === PaymentType.EFT && (
								<>
									{!!orderData?.orderNumber && (
										<Typography variant="body2" sx={{ marginTop: '1.5rem' }}>
											{`Reference: ${OrderNumberPrefix}${orderData.orderNumber}`}
										</Typography>
									)}
									<Typography variant="caption" sx={{ marginTop: '1rem', color: '#555' }}>
										Your order is almost complete! We are just waiting for your EFT payment.
										<br />
										To make the payment, please use one of the following EFT details:
									</Typography>

									<Divider sx={{ margin: '1.5rem 0' }} />
									<Typography variant="caption" sx={{ color: '#555' }}>
										<b>Standard Bank Benoni</b>
										<br />
										Account No: 020971117
										<br />
										Branch Code: 013042
									</Typography>
									<Typography variant="caption" sx={{ marginTop: '1rem', color: '#555' }}>
										<b>Nedbank Business East Rand</b>
										<br />
										Account No: 1207564400
										<br />
										Code: 198765
									</Typography>
								</>
							)}

							{paymentData?.paymentTypeId === PaymentType.CreditCardOnCollection && (
								<>
									{!!orderData?.orderNumber && (
										<Typography variant="body2" sx={{ marginTop: '1.5rem' }}>
											{`Reference: ${OrderNumberPrefix}${orderData.orderNumber}`}
										</Typography>
									)}
									<Typography variant="caption" sx={{ marginTop: '1rem', color: '#555' }}>
										Your order has been received.
									</Typography>
									<Divider sx={{ margin: '1.5rem 0' }} />
									<Typography variant="caption" sx={{ color: '#555' }}>
										<b>Collection Address</b>
										<br />
										Argus Motor Company
										<br />
										28 Great North Road
										<br />
										Brentwood Park
										<br />
										Benoni, 1501
									</Typography>
									<Typography variant="caption" sx={{ marginTop: '1rem', color: '#555' }}>
										<b>Collection Times</b>
										<br />
										Mon - Thurs 8:00am – 4:30pm
										<br />
										Fri 8:00am – 1:30pm
									</Typography>
									<Typography variant="caption" sx={{ marginTop: '1.5rem', color: '#555' }}>
										Please note that we will only process your order once you arrive and make payment.
										Additional time may be required for order processing.
									</Typography>
								</>
							)}

							<Button
								variant="contained"
								color="error"
								size="small"
								sx={{ width: '100%', marginTop: '2rem' }}
								onClick={handleCloseClick}
							>
								Close
							</Button>
						</Paper>
					</Container>
				</Box>
			}
		</>
	);
};

export default PaymentConfirmation;
