import React from "react";
import {
	Backdrop,
	Box,
	Button,
	CircularProgress, Container,
	Grid,
	Typography
} from "@mui/material";
import { find } from "lodash";
import GridItem from "../GridItem";
import SwiperCore from "swiper";
import { Autoplay, Navigation } from "swiper/modules";
import CategorySlider from "../CategorySlider";

SwiperCore.use([Autoplay, Navigation ]);

const DesktopFindProductsByCategory = ({
	categoriesAndTypes,
	activeCategory,
	handleActiveCategoryChange,
	products,
	pricingData,
	productCategoryLink,
	handleAddToCart,
	customerCategory,
	loading
}) => {
	return (
		<Container maxWidth="lg">
			<Box sx={{ mt: 4, p: 2 }}>
				<Typography variant="h4" gutterBottom sx={{ textAlign: "center"}}>
					Find Part by Category
				</Typography>

				<Box sx={{ height: '32px' }} />

			</Box>

			<CategorySlider categoriesAndTypes={categoriesAndTypes}
			                handleActiveCategoryChange={handleActiveCategoryChange}
			                activeCategory={activeCategory}

			/>

			<Box sx={{ mt: 4, p: 2 }}>
				<Box sx={{ height: '32px' }} />

				<Grid container alignItems="center" justifyContent="space-between">
					<Grid item>
						<Typography variant="h2"
						            sx={{ borderLeft: "3px solid red"}}
						>
							&nbsp;{activeCategory}
						</Typography>
					</Grid>
					<Grid item>
						<Button color="error"
						        size="small"
						        variant="contained"
						        href={productCategoryLink}>
							View all
						</Button>
					</Grid>
				</Grid>

				<Box sx={{ height: '32px' }} />

				<Box sx={{ p: 3 }}>
					<Grid spacing={2} container>
						{!!products && !!pricingData && products.slice(0, 4).map((product, itemNumber) => {
							const priceObject = find(pricingData, p => p.sku === product.sku);
							const itemPrice = priceObject?.price ?? 0;
							const invoicePrice = priceObject?.invoicePrice ?? 0;
							return (
								<Grid item md={3} lg={3} key={itemNumber}>
									<GridItem
										key={itemNumber}
										title={product.name}
										price={itemPrice}
										invoicePrice={invoicePrice}
										customerCategory={customerCategory}
										vendor={product.vendor}
										image={product.image}
										productId={product.productId}
										vendorImage={product.vendorImage}
										handle={product.handle}
										vendorDescription={product.vendorDescription}
										quality={product.quality}
										fitment={product.fitment}
										availability={product.availability}
										warranty={product.warranty}
										warrantyText={product.warrantyText}
										applicationPosition={product.applicationPosition}
										applicationPositionImage={product.applicationPositionImage}
										// showVehicleSpecificInformation={showVehicleSpecificInformation}
										// isKiosk={isKiosk}
										bodyHtml={product.html}
										sku={product.sku}
										addToCart={() => handleAddToCart(product)}
									/>
								</Grid>
							);
						})}
					</Grid>
				</Box>

				<Backdrop
					sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
					open={loading}
				>
					<CircularProgress color="inherit"/>
				</Backdrop>

			</Box>
		</Container>

	)
}

export default DesktopFindProductsByCategory;