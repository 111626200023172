export const storage_vehicle = "9e63097a-6bd3-4060-b5f3-6e5cb7e73cde";
export const local_storage_token = "1c28ed04-5862-443a-80a8-5e0674a97651";
export const session_storage_token = "bacd1f55-e569-46a7-920b-c8d85474a17f";
export const local_storage_refresh_token =
	"185b0c5b-b52d-44c3-93d3-9e040643ad3b";

export const OrderNumberPrefix = "ARG";

export const ScreenSize = Object.freeze({
	Mobile: Symbol(1),
	Tablet: Symbol(2),
	Desktop: Symbol(3),
})

export const ScreenSizeBreakPoints = {
	MobileMax: "767px",
	TabletMin: "768px",
	TabletMax: "1023px",
	DesktopMin: "1024px",
}

export const LoggedInStatus = Object.freeze({
	NotChecked: Symbol(1000),
	Yes: Symbol(1001),
	Refreshed: Symbol(1002),
	No: Symbol(1003)
});

export const CheckoutStatus = {
	NotStarted: "01924f56-8575-3e24-1779-aadc9f53be3f",
	Initialized: "01924f56-ba10-024f-7d01-1ec9e9d5e6fd",
	InProgress: "01924f56-ce12-e764-f12f-1a96bd1dbf2b",
	Finalized: "01924f56-e053-6de0-2a95-9841e15a7476",
	Complete: "01924f56-f6f9-0307-aee7-87cfec130c68",
	Abandoned: "01924f5e-3515-f8a1-e87b-7e2c77215565"
};

export const PaymentType = {
	AccountCredit: "6fab1f25-5c4b-4681-9aa7-c6f245ba039e",
	PayFast: "b4ef31f0-05e3-49ab-940f-fe42ddaa6e89",
	EFT: "87c316c5-1b31-45e8-a3af-c39f4385436a",
	CreditCardOnCollection: "a9ca4819-da6a-4843-9d95-670c70ae7322"
};

export const PaymentStatus = {
	Complete: "417c2635-24d9-4687-9032-a679dbee6e74",
	Failed: "ebb5979d-5645-4e8a-8e20-33870b91a587",
	Pending: "810b136b-0b48-4756-9196-a3cf5bc65614",
	Voided: "a0502a00-fa8a-42b5-ac3e-f17242c13ca7",
	Overdue: "c8971eae-42de-4bcc-9349-c10477763d95"
};

export const OrderStatus = {
	Open: "83412650-9ef8-4858-b732-8905a7e98d2b",
	Closed: "6e101585-dc6b-48ff-9714-c48b81fecc37",
	Archived: "5870e75b-db99-4c32-8db1-797699640a62",
	Cancelled: "877eac74-1e4f-4659-98dd-4d4f99aa82b1"
};

export const FulfillmentStatus = {
	Complete: "9f196a33-bc2c-4e47-b37d-6c69e5289da7",
	Unfulfilled: "4fc90e38-0653-4b79-8de7-557d517cc3e2",
	New: "5ab7f020-c141-4e03-a438-52c7e69999c3",
	Scheduled: "9bd94c55-9392-4073-adc5-f82fa62cf286",
	OnHold: "be1034c7-4bb0-4af6-a3b1-296ea5760d3a",
	AwaitingShipment: "c80d9052-ec08-4d30-96a3-4b8b88e0982a",
	ReadyForCollection: "622f7c7c-e763-4ce3-8ac9-b45b302f1c6d",
	Fulfilled: "b53d6d30-c261-481d-a363-19d4fc8ab627"
};

export const OrderPaymentStatus = {
	Paid: "a618a2b1-9108-46fa-aa05-e05641a01c58",
	PartiallyPaid: "e5bdfa70-4478-4226-8b6e-b3066b1593e6",
	PendingPayment: "c07203c3-c4d3-4d80-8e3c-1d71efee0a3e",
	Unpaid: "7ca57b4f-6728-4084-b1b8-07f8f47aaa5d",
	Refunded: "a870998f-7a85-499c-bdeb-adb2add7a6c6",
	PartiallyRefunded: "8ca91f97-e616-45b3-b05e-7297546d5117"
};

export const OrderFulfillmentStatus = {
	Complete: "a4472632-f647-491b-9a5b-b928cc1d2d44",
	PartiallyUnfulfilled: "896235da-eab2-47b2-ba19-c19fd8d2bc90",
	Unfulfilled: "bac6f9e7-73b2-4051-89e5-31721125a80b",
	Scheduled: "f7cf9795-298a-4746-aee2-add7ae67e0da",
	OnHold: "07bc08b7-ca8a-4b4e-92af-dd29facabafa",
	New: "40a58f90-472f-4d71-8b86-47395d1e1599",
	AwaitingShipment: "e8bfed83-fc64-407d-8516-78f1365113ac",
	ReadyForCollection: "429960e8-f858-4021-8ef7-b13072c62423",
	Fulfilled: "8f92fde6-c93f-480e-8acb-d2c1b5299712"
};

export const PaymentConfirmationMessage = {
	HeadingComplete: "Order completed successfully!",
	HeadingPartialComplete: "Payment completed successfully!",
	HeadingFailed: "Something went wrong!",
	HeadingPending: "Awaiting payment",
	MessageComplete: "Your order is accepted.",
	MessagePartialComplete: "Please pay the balance to complete your order.",
	MessageFailed: "Payment could not be completed.",
	MessagePending: "Your order will be fulfilled once payment is received."
};

export const OrderLogisticsStatus = {
	Ready: "89e24fd2-8d68-4a2a-aadc-2021c2133570",
	Pending: "7337a2a0-73c7-4a2c-86cc-2e29f1a0b754",
	MethodChosen: "090c4d3d-9b63-466b-956d-4c0a3d705f68"
};

export const AccountType = {
	Commercial: "019126aa-c379-083b-b6ca-9761b3a4c4f8",
	Retail: "019126aa-6bbf-5751-2b84-f1c35ac40fcc"
};

export const DeliveryMethod = {
	Collection: "pickup",
	Ship: "ship"
};

export const LogisticsMethod = {
	Collection: "4c2d06ab-f3a7-4308-9541-7a9ac7f6d18c",
	Ship: "cbd12dbb-8f07-4042-bf0c-ef2121aaad88"
};

export const AddressType = {
	Billing: Symbol(1000),
	Shipping: Symbol(1001),
	Collection: Symbol(1002)
};

export const PaymentMethod = {
	AccountCredit: "AccountCredit",
	PayFast: "Payfast",
	EFT: "EFT",
	CardOnCollection: "CreditCardOnCollection"
};

export const default_ship_via = "155";
export const ProductAvailability = {
	InStock: "In Stock",
	LowStock: "Low Stock",
	ThreeToFiveDays: "3-5 Days",
	No_Stock: "No Stock"
};

export const PricingOutput = {
	Product: "product",
	Cart: "cart",
	CartSummary: "cart-summary"
};

export const PayfastResponsePaymentStatus = {
	Complete: "COMPLETE",
	Cancelled: "CANCELLED"
};

export const PageStatus = {
	NotLoaded: Symbol(1000),
	AuthCheckStarted: Symbol(1001),
	AuthChecksFailed: Symbol(1002),
	AuthChecksCompleteDataFetchNotStarted: Symbol(1003),
	DataFetchStarted: Symbol(1004),
	DataFetchComplete: Symbol(1005),
	DataFetchFailed: Symbol(1006)
};

export const ApiCallStatus = Object.freeze({
	NotStarted: Symbol(2000),
	InProgress: Symbol(2001),
	Succeeded: Symbol(2002),
	Failed: Symbol(2003),
	Error: Symbol(2004)
});

export const CustomerCategory = Object.freeze({
	Retail: "RET",
	Trade: "TRA",
	Wholesale1: "WS1",
	Wholesale2: "WS2",
	Wholesale3: "WS3",
	Wholesale5: "WS5",
	TradeNett: "NTR",
	Wholesale1Nett: "NW1",
	Wholesale2Nett: "NW2"
});

export const FilterType = Object.freeze({
	VehicleMake: "makes",
	VehicleModel: "models",
	VehicleYear: "years",
	ProductCategory: "productCategories",
	ProductType: "productTypes",
	Vendor: "vendors"
});

export const AnnouncementType = Object.freeze({
	Informational: 1,
	Acknowledgement: 2,
	Promotional: 3
});

export const PreferenceIds = Object.freeze({
	AccountCurrentTradeAccountId: "8856f482-e9c5-4b22-af64-e7b752944f85",
	AccountCurrentUserVehicle: "019046ca-8bba-d4f8-e142-7872c9c27d77",
	PreferenceIdAccountType: "019126a9-0e00-13c1-6270-c35d8c2ed293",
	CheckoutDefaultSalesPerson: "1b220b8d-dc7c-4053-9e64-e42091c1638d",
	CheckoutDefaultBillingAddress: "78255450-f65c-4ffe-81fa-c3a5d1e6aec3",
	CheckoutDefaultShippingAddress: "ac7e932a-142c-4365-9e11-f9a8096b55c4",
	CheckoutDefaultLogisticsMethod: "acd4c627-1366-43e3-873f-24f363e2e11c",
});

export const PreferenceValues = Object.freeze({
	PreferenceValueAccountTypeRetail: "019126aa-6bbf-5751-2b84-f1c35ac40fcc",
	PreferenceValueAccountTypeCommercial: "019126aa-c379-083b-b6ca-9761b3a4c4f8",
	PreferenceValueAccountTypeGuest: "019189f1-6bd0-65e5-ef2c-3ac9e7610298",
});

export const ApiHeaders = Object.freeze({
	WebsiteId: "ahwid"
});

export const DefaultAnnouncementObject = '{"seen": [], "acknowledged": []}'