import React from "react";
import "./style.css";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import SwiperCore from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { Button, Typography, Link as MuiLink, Box } from "@mui/material";
import { useMediaQuery } from "react-responsive";

SwiperCore.use([Autoplay, Navigation]);

const Hero = () => {

	const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

	const heroItems = [
		{
			showButton: false,
			image: () => `https://argusweb.azureedge.net/argus-web/home/hero-1${isMobile ? "-mobile" : ""}.jpg`,
		},
		{
			showButton: true,
			image: () => `https://argusweb.azureedge.net/argus-web/home/hero-2${isMobile ? "-mobile" : ""}.jpg`,
			buttonText: "Learn More",
			headingText: "Established in 1955",
			link: "/about",
		},
		{
			showButton: true,
			image: () => `https://argusweb.azureedge.net/argus-web/home/hero-3${isMobile ? "-mobile" : ""}.jpg`,
			buttonText: "Go",
			headingText: "Meet the Team",
			link: "/team",
		}
	];

	return (
		<section>
			<Swiper
				modules={[Autoplay, Navigation]}
				spaceBetween={50}
				slidesPerView={1}
				navigation
				loop={true}
				pagination={{clickable: true}}
				autoplay={{
					delay: 3000,
					disableOnInteraction: true
				}}
			>
				{!!heroItems && heroItems.map((item, index) => {
					const heroImage = item.image();
					return (
						<SwiperSlide key={index}>
							<Box sx={{
								position: 'relative',
								width: '100%',
								height: { xs: '30vh', md: '40vh' },
								overflow: 'hidden'
							}}>
								{!!item.showButton &&
									<Box sx={{
										position: 'absolute',
										top: 0,
										left: 0,
										width: '100%',
										height: '100%',
										backgroundColor: 'rgba(0, 0, 0, 0.5)',
										zIndex: 1
									}} />
								}

								<img src={heroImage}
								     alt={`Slide ${index + 1}`}
								     style={{
									     width: "100%",
									     height: '100%',
									     objectFit: 'cover',
									     objectPosition: 'center',
								     }}
								/>

								{item.showButton && (
									<Box sx={{
										position: 'absolute',
										top: "50%",
										left: 0,
										width: '100%',
										height: '100%',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'flex-start',
										alignItems: 'center',
										color: '#fff',
										zIndex: 2
									}}
									>
										<Typography variant={isMobile ? "h6" : "h4"} sx={{ mb: 2, color: '#fff' }}>
											{item.headingText}
										</Typography>
										<MuiLink component={Link} to={item.link} sx={{ textDecoration: 'none' }}>
											<Button
												variant="contained"
												size="small"
												sx={{
													backgroundColor: '#d32f2f',
													color: '#fff',
													fontSize: isMobile ? 'var(--font-size-xs)' : 'var(--font-size-s)',
													'&:hover': {
														backgroundColor: '#b71c1c',
													},
												}}
											>
												{item.buttonText}
											</Button>
										</MuiLink>
									</Box>
								)}
							</Box>
						</SwiperSlide>
					)
				})}
			</Swiper>
		</section>
	);
};

export default Hero;
