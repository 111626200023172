import React, { Fragment } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Grid, Link, Typography } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import { useMediaQuery } from "react-responsive";
import PageHeader from "../PageHeader";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Team = () => {
	const teamInfo = [
		{
			title: "Wholesale Sales Team",
			members: [
				{
					name: "Calvin Mokolane",
					image: "https://argusweb.azureedge.net/argus-web/team/team-sales-calvin.png",
					email: "calvinm@argusmotoring.co.za",
				},
				{
					name: "Cameron Sewmungal",
					image: "https://argusblob01.blob.core.windows.net/argus-web/team/team-placeholder.png",
					email: "camerons@argusmotoring.co.za",
				},
				{
					name: "Elsa Dyssel",
					image: "https://argusweb.azureedge.net/argus-web/team/team-sales-elsa.png",
					email: "elsad@argusmotoring.co.za",
				},
				{
					name: "Laurence Rungasamy",
					image: "https://argusweb.azureedge.net/argus-web/team/team-sales-lawrence.png",
					email: "laurencer@argusmotoring.co.za",
				},
				{
					name: "Vera Venema",
					image: "https://argusweb.azureedge.net/argus-web/team/team-sales-vera.png",
					email: "veroniquev@argusmotoring.co.za",
				},
				{
					name: "Wiseman Sibiya",
					image: "https://argusweb.azureedge.net/argus-web/team/team-sales-wiseman.png",
					email: "wisemans@argusmotoring.co.za",
				},
			],
		},
		{
			title: "Hardware Sales Team",
			members: [
				{
					name: "Amy Walbank",
					image: "https://argusblob01.blob.core.windows.net/argus-web/team/team-sales-amy.jpeg",
					email: "amyw@argusmotoring.co.za",
				},
				{
					name: "Sanaa Dawood",
					image: "https://argusweb.azureedge.net/argus-web/team/team-sales-sanaa.png",
					email: "sanaad@argusmotoring.co.za",
				},
			],
		},
		{
			title: "Trade Counter Sales Team (Workshop & Fitment)",
			members: [
				{
					name: "Danjelle Swart",
					image: "https://argusblob01.blob.core.windows.net/argus-web/team/team-sales-danjelle.jpg",
					email: "danjelles@argusmotoring.co.za",
				},
				{
					name: "Dumisane Chimsonde",
					image: "https://argusblob01.blob.core.windows.net/argus-web/team/team-sales-dumsani.JPG",
					email: "dumsanic@argusmotoring.co.za",
				},
				{
					name: "Sihle Sibanda",
					image: "https://argusblob01.blob.core.windows.net/argus-web/team/team-liaisons-sihle.JPG",
					email: "sihlesenkosis@argusmotoring.co.za",
				},
				{
					name: "Vincent Ludick",
					image: "https://argusblob01.blob.core.windows.net/argus-web/team/team-liaisons-vincent.JPG",
					email: "vincentl@argusmotoring.co.za",
				},
			],
		},
		{
			title: "Department Liaisons",
			members: [
				{
					name: "Meryke Thysse",
					role: "Warehouse/Logistics",
					image: "https://argusblob01.blob.core.windows.net/argus-web/team/team-liaisons-meryke.JPG",
					email: "meryket@argusmotoring.co.za",
				},
				{
					name: "Natasha Naidoo",
					role: "Accounts",
					image: "https://argusblob01.blob.core.windows.net/argus-web/team/team-liaisons-natasha.png",
					email: "natashan@argusmotoring.co.za",
				},
				{
					name: "Shannon Domingo",
					role: "General Management",
					image: "https://argusblob01.blob.core.windows.net/argus-web/team/team-liaisons-shannon.png",
					email: "shannond@argusmotoring.co.za",
				},
			],
		},
		{
			title: "Management",
			members: [
				{
					name: "Larry Raff",
					role: "Director",
					image: "https://argusweb.azureedge.net/argus-web/team/team-management-larry.png",
				},
				{
					name: "Mark Raff",
					role: "Director",
					image: "https://argusweb.azureedge.net/argus-web/team/team-management-mark.png",
				},
				{
					name: "Moishe Raff",
					role: "COO",
					image: "https://argusweb.azureedge.net/argus-web/team/team-management-moshe.png",
				},
				{
					name: "Baruch Raff",
					role: "CTO",
					image: "https://argusweb.azureedge.net/argus-web/team/team-management-baruch.png",
				},
				{
					name: "Shifra Lazarus",
					role: "CFO",
					image: "https://argusweb.azureedge.net/argus-web/team/team-management-shifra.png",
				},
			],
		},
	];

	const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

	const paragraphText = "Our team is dedicated to providing exceptional service and support across all areas of our\n" +
		"\t\t\t\t\t\tbusiness. You can trust that you’re in capable hands with our experienced and knowledgeable\n" +
		"\t\t\t\t\t\tteam members, who are here to assist with your needs. We are committed to providing personalized\n" +
		"\t\t\t\t\t\tsolutions and delivering excellence in everything we do."


	return (
		<Fragment>
			<PageHeader title="Meet the team" />
			{isMobile === true &&
				<Box sx={{ padding: '16px' }}>
					<Typography variant="caption"
					            paragraph
					            sx={{ mb: 2, lineHeight: 1.5 }}>
						{paragraphText}
					</Typography>
					{teamInfo.map((team, index) => (
						<Box key={index} sx={{ marginBottom: '24px' }}>
							<Accordion>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls={`panel${index}-content`}
									id={`panel${index}-header`}
									sx={{
										backgroundColor: '#f5f5f5',
										borderRadius: '8px',
									}}
								>
									<Typography
										variant="h6"
										sx={{
											fontWeight: 'bold',
											textAlign: 'center',
											width: '100%',
											color: '#333',
										}}
									>
										{team.title}
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									{team.members.map((member, idx) => (
										<Box key={idx} sx={{ textAlign: 'center', mb: 3 }}>
											<Avatar
												alt={member.name}
												src={member.image}
												sx={{ width: 120, height: 120, margin: '0 auto', mb: 1 }}
											/>
											<Typography variant="subtitle1" fontWeight="bold">
												{member.name}
											</Typography>
											{member.role && (
												<Typography variant="body2" color="textSecondary">
													{member.role}
												</Typography>
											)}
											{member.email && (
												<Box display="flex" justifyContent="center" alignItems="center" mt={1}>
													<EmailIcon sx={{ fontSize: 16, mr: 0.5 }} />
													<Link href={`mailto:${member.email}`} sx={{ fontSize: 14 }}>
														{member.email}
													</Link>
												</Box>
											)}
										</Box>
									))}
								</AccordionDetails>
							</Accordion>
						</Box>
					))}
				</Box>
			}
			{isMobile === false &&
				<Box sx={{ p: 2 }}>
					<Typography variant="body2"
					            paragraph
					            sx={{ mb: 3, lineHeight: 1.8 }}
					>
						{paragraphText}
					</Typography>
					{teamInfo.map((team, index) => (
						<Accordion key={index} defaultExpanded={false} sx={{ marginBottom: '24px' }}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls={`panel${index}-content`}
								id={`panel${index}-header`}
							>
								<Typography variant="body1" sx={{ fontWeight: 'bold', color: '#333', textAlign: 'center', width: '100%' }}>
									{team.title}
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid container spacing={4} justifyContent="center">
									{team.members.map((member, idx) => (
										<Grid item xs={12} sm={6} md={4} lg={3} key={idx}>
											<Box
												sx={{
													textAlign: 'center',
													padding: '16px',
													borderRadius: '8px',
													boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
													backgroundColor: '#fff',
													'&:hover': {
														boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
													},
												}}
											>
												<Avatar
													alt={member.name}
													src={member.image}
													sx={{
														width: 120,
														height: 120,
														margin: '0 auto',
														mb: 2,
													}}
												/>
												<Typography variant="caption"
												            fontWeight="bold"
												            sx={{
																marginBottom: '4px',
													            display: "block"
												            }}>
													{member.name}
												</Typography>
												{member.role && (
													<Typography variant="caption" color="textSecondary" sx={{ marginBottom: '8px' }}>
														{member.role}
													</Typography>
												)}
												{member.email && (
													<Box
														sx={{
															display: 'flex',
															justifyContent: 'center',
															alignItems: 'center',
															mt: 1,
														}}
													>
														<EmailIcon sx={{ fontSize: 16, mr: 0.5 }} />
														<Link href={`mailto:${member.email}`} sx={{ display: "block"}}>
															<Typography variant="caption"
															            sx={{
																			color: '#0073e6',
																			textDecoration: 'none',
																			textTransform: 'none',
																			'&:hover': {
																				textDecoration: 'underline',
																			}
															}}>
																contact
															</Typography>
														</Link>
													</Box>
												)}
											</Box>
										</Grid>
									))}
								</Grid>
							</AccordionDetails>
						</Accordion>
					))}
				</Box>
			}
		</Fragment>
	);
};
export default Team;
