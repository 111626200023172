import React, { useEffect, useRef, useState } from "react";
import {
	AppBar,
	Badge,
	Box,
	Divider,
	IconButton,
	InputBase,
	List,
	ListItemButton,
	ListItemText,
	Paper,
	styled,
	Toolbar,
	Typography,
	ListItemAvatar,
	Avatar,
	Link, Drawer, CircularProgress, ListItem, Stack
} from "@mui/material";
import Image from "../Image";
import SearchIcon from "@mui/icons-material/Search";
import GarageOutlinedIcon from "@mui/icons-material/GarageOutlined";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useAuth0 } from "@auth0/auth0-react";
import useArgusSearch from "../../hooks/ArgusSearchHook";
import { SouthAfricanRand } from "../../utils/helpers";
import CustomButton from "../CustomButton";
import MenuIcon from "@mui/icons-material/Menu";
import cssVariables from "../../utils/cssVariables";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardOutlined";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { capitalize, lowerCase, upperCase } from "lodash";


const TabletHeader = ({
	gotoLoginPage,
	gotoRegistrationPage,
	gotoCartPage,
	performLogout,
	cartItems,
	totalGarageItems,
	openSavedVehiclesModal,
	customerCategory,
	categoriesAndTypes
}) => {
	const { isAuthenticated, isLoading } = useAuth0();
	const { loading, pricingData, products, setSearchTerm } = useArgusSearch(customerCategory);
	const [searchInput, setSearchInput] = useState("");
	const [debouncedTerm, setDebouncedTerm] = useState(searchInput);
	const [menuOpen, setMenuOpen] = useState(false);
	const [searchOpen, setSearchOpen] = useState(false);
	const [categoriesDrawerOpen, setCategoriesDrawerOpen] = useState(false);
	const [typesDrawerOpen, setTypesDrawerOpen] = useState(false);
	const [categoryToDisplay, setCategoryToDisplay] = useState(null);
	const searchInputRef = useRef(null);

	const toggleMenu = () => {
		setMenuOpen(!menuOpen);
	}

	const toggleSearch = () => {
		setSearchOpen(!searchOpen);
	}

	const toggleCategories = () => {
		setCategoriesDrawerOpen(!categoriesDrawerOpen);
	}

	const toggleTypes = () => {
		setTypesDrawerOpen(!typesDrawerOpen);
	};

	const handleCategoryClick = (category) => {
		setCategoryToDisplay(category);
		toggleTypes();
	}

	const handleCloseTypesClick = () => {
		setCategoryToDisplay(null);
		toggleTypes();
	}

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedTerm(searchInput);
		}, 300);

		return () => {
			clearTimeout(handler);
		};
	}, [searchInput]);

	useEffect(() => {
		if (debouncedTerm) {
			setSearchTerm(debouncedTerm);
		}
	}, [debouncedTerm]);


	return (
		<>
			<AppBar position="static">
				<Toolbar sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 16px' }}>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<CustomButton
							onClick={toggleMenu}
							basic
							startIcon={<MenuIcon />}
							color="#333"
							sx={{ marginRight: '16px' }}
						/>
						<Image
							src="https://argusweb.azureedge.net/argus-web/logo.svg"
							alt="Logo"
							href="/"
							size="lg"
						/>
					</Box>

					<Box sx={{ display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'flex-end' }}>
						<IconButton sx={{ color: 'inherit' }} onClick={toggleSearch}>
							<SearchIcon />
						</IconButton>
						<IconButton onClick={openSavedVehiclesModal}>
							<Badge badgeContent={totalGarageItems} color="error">
								<GarageOutlinedIcon sx={{ color: 'inherit' }} />
							</Badge>
						</IconButton>
						<IconButton onClick={gotoCartPage}>
							<Badge badgeContent={cartItems} color="error">
								<ShoppingCartIcon sx={{ color: 'inherit' }} />
							</Badge>
						</IconButton>
					</Box>
				</Toolbar>
			</AppBar>

			<Drawer
				anchor="left"
				open={menuOpen}
				onClose={toggleMenu}
				sx={{ width: '50%', '& .MuiDrawer-paper': { width: '50%' } }}
			>
				<Box sx={{ padding: "2px", backgroundColor: cssVariables.backgroundColor }}>
					<Paper sx={{ width: "100%", marginBottom: "1rem" }}>
						<Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', paddingRight: "5px" }}>
							<Box sx={{ width: "100%" }}>
								<Image
									src="https://argusweb.azureedge.net/argus-web/logo.svg"
									alt="Logo"
									size="md"
								/>
							</Box>
							<CustomButton
								onClick={toggleMenu}
								startIcon={<CloseIcon />}
								color="#333"
								basic
								sx={{ flexShrink: 0 }}
							/>
						</Box>
					</Paper>
					<Paper sx={{ width: "100%", marginBottom: "1rem" }}>
						<List>
							<ListItemButton href="/">
								<ListItemText primary="Home" />
							</ListItemButton>
							<Divider component="li" />
							<ListItemButton href="#" onClick={toggleCategories}>
								<ListItemText primary="Shop by category" />
								<IconButton sx={{ flexShrink: 0 }}>
									<ArrowForwardIcon />
								</IconButton>
							</ListItemButton>
							<Divider component="li" />
							<ListItemButton href="/about">
								<ListItemText primary="About" />
							</ListItemButton>
							<Divider component="li" />
							<ListItemButton href="/faq">
								<ListItemText primary="FAQ" />
							</ListItemButton>
							<Divider component="li" />
							<ListItemButton href="/contact-us">
								<ListItemText primary="Contact Us" />
							</ListItemButton>
							<Divider component="li" />
							{!isLoading && (
								<>
									{!isAuthenticated ? (
										<>
											<ListItemButton href="#" onClick={gotoLoginPage}>
												<ListItemText primary="Login" />
											</ListItemButton>
											<Divider component="li" />
											<ListItemButton href="#" onClick={gotoRegistrationPage}>
												<ListItemText primary="Register" />
											</ListItemButton>
										</>
									) : (
										<ListItemButton href="#" onClick={performLogout}>
											<ListItemText primary="Logout" />
										</ListItemButton>
									)}
								</>
							)}
						</List>
					</Paper>
				</Box>
			</Drawer>

			<Drawer
				anchor="top"
				open={searchOpen}
				onClose={toggleSearch}
				sx={{ height: '100vh', overflowY: 'auto' }}
			>
				<Box sx={{ padding: 2, display: 'flex', alignItems: 'center', backgroundColor: cssVariables.backgroundColor }}>
					<input
						placeholder="Search…"
						value={searchInput}
						onChange={(e) => setSearchInput(e.target.value)}
						ref={searchInputRef}
						style={{
							width: '100%',
							padding: '10px',
							borderRadius: '4px',
							border: '1px solid #ccc',
							fontSize: '1rem',
						}}
					/>
					<Typography
						variant="body2"
						sx={{ marginLeft: 2, cursor: 'pointer' }}
						onClick={toggleSearch}
					>
						Cancel
					</Typography>
				</Box>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: loading ? '100px' : '0px',
						transition: 'height 0.3s ease-in-out',
						opacity: loading ? 1 : 0,
					}}
				>
					{loading && <CircularProgress />}
				</Box>
				<List>
					{!!products && !!pricingData && products.length > 0 && (
						<>
							{products.map((product, index) => {
								const priceObject = pricingData.find(p => p.sku === product.sku);
								const itemPrice = priceObject?.price ?? 0;
								const invoicePrice = priceObject?.invoicePrice ?? 0;

								return (
									<React.Fragment key={index}>
										<ListItem
											alignItems="flex-start"
											component="a"
											href={`/product/${product.sku}`}
											sx={{
												textDecoration: 'none',
												color: 'inherit',
											}}
										>
											<ListItemAvatar>
												<Avatar alt={product.sku} src={product.image} />
											</ListItemAvatar>
											<ListItemText
												primary={<Typography variant="h6">{product.name}</Typography>}
												secondary={
													<>
														<Typography variant="body2" color="textPrimary">
															{SouthAfricanRand.format(itemPrice)}
														</Typography>
														{invoicePrice > 0 && (
															<Typography
																sx={{ display: 'inline', marginLeft: 1 }}
																component="span"
																variant="body2"
																color="textSecondary"
															>
																(Invoice: {SouthAfricanRand.format(invoicePrice)})
															</Typography>
														)}
													</>
												}
											/>
											<IconButton
												color="error"
												onClick={(e) => {
													e.preventDefault();
													handleAddToCart({
														id: product.productId,
														name: product.name,
														vendor: product.vendor,
														image: product.image,
														sku: product.sku,
													});
												}}
												sx={{ ml: 2 }}
											>
												<AddShoppingCartIcon />
											</IconButton>
										</ListItem>
										{index < products.length - 1 && <Divider component="li" />}
									</React.Fragment>
								);
							})}
						</>
					)}
				</List>
			</Drawer>

			<Drawer
				anchor="left"
				open={categoriesDrawerOpen}
				onClose={toggleCategories}
				sx={{ width: '50%', '& .MuiDrawer-paper': { width: '50%' } }}
			>
				<Box sx={{ padding: "2px", backgroundColor: cssVariables.backgroundColor }}>
					<Paper sx={{ width: "100%", marginBottom: "1rem", position: 'sticky', top: 0, zIndex: 1100 }}>
						<Box sx={{ width: "100%" }}>
							<Stack direction="column">
								<Box sx={{ alignItems: "left", paddingTop: "1rem" }} onClick={toggleCategories}>
									<CustomButton text="Menu"
									              startIcon={<ArrowBackIcon />}
									              color={cssVariables.blueButtonColor}
									              basic />
								</Box>
								<Box sx={{ alignItems: "left", paddingLeft: "1rem" }}>
									<Typography variant="h6" gutterBottom>
										Shop by Category
									</Typography>
								</Box>
							</Stack>
						</Box>
					</Paper>
					<Paper sx={{ width: "100%", marginBottom: "1rem" }}>
						<List>
							{categoriesAndTypes.map((category, i) => {
								const categoryName = capitalize(category.category.replaceAll("_", " ").toLowerCase());

								return (
									<div key={i}>
										<ListItemButton href="#" onClick={() => handleCategoryClick(category)}>
											<ListItemText primary={categoryName} />
											<IconButton sx={{ flexShrink: 0 }}>
												<ArrowForwardIcon />
											</IconButton>
										</ListItemButton>
										{i < categoriesAndTypes.length - 1 && <Divider component="li" />}
									</div>
								);
							})}
						</List>
					</Paper>
				</Box>
			</Drawer>

			{/* Types Drawer */}
			{!!categoryToDisplay && (
				<Drawer
					anchor="left"
					open={typesDrawerOpen}
					onClose={handleCloseTypesClick}
					sx={{ width: '50%', '& .MuiDrawer-paper': { width: '50%' } }}
				>
					<Box sx={{ padding: "2px", backgroundColor: cssVariables.backgroundColor }}>
						<Paper sx={{ width: "100%", marginBottom: "1rem", position: 'sticky', top: 0, zIndex: 1100 }}>
							<Box sx={{ width: "100%" }}>
								<Stack direction="column">
									<Box sx={{ alignItems: "left", paddingTop: "1rem" }} onClick={handleCloseTypesClick}>
										<CustomButton text="Shop by category"
										              startIcon={<ArrowBackIcon />}
										              color={cssVariables.blueButtonColor}
										              basic />
									</Box>
									<Box sx={{ alignItems: "left", paddingLeft: "1rem" }}>
										<Typography variant="h6" gutterBottom>
											{capitalize(categoryToDisplay.category.replaceAll("_", " ").toLowerCase())}
										</Typography>
									</Box>
								</Stack>
							</Box>
						</Paper>
						<Paper sx={{ width: "100%", marginBottom: "1rem" }}>
							<List>
								{categoryToDisplay.productTypes.map((type, i) => {
									const categorySlug = lowerCase(categoryToDisplay.category).replaceAll(" ", "_").trim();
									let typeName;
									let typeSlug;
									if (lowerCase(type) === "view all") {
										typeName = upperCase(type);
										typeSlug = `/products/${categorySlug}`;
									} else {
										typeName = capitalize(type.replaceAll("_", " ").trim());
										typeSlug = `/products/${categorySlug}/${type.toLowerCase()}`;
									}

									return (
										<div key={i}>
											<ListItemButton href={typeSlug}>
												<ListItemText primary={typeName} />
											</ListItemButton>
											{i < categoryToDisplay.productTypes.length - 1 && <Divider component="li" />}
										</div>
									);
								})}
							</List>
						</Paper>
					</Box>
				</Drawer>
			)}
		</>
	)
}

export default TabletHeader;