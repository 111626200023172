import { getManufacturers, getModels, getVariants, getVehicles, getYears } from "../../api/vehicleController";
import { capitalize } from "lodash";

export const fetchManufacturers = async ({
	year,
	setManufacturerData,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getManufacturers(year);
		if (response.success) {
			setManufacturerData(response.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (e) {
		console.error(e);
		setStatusError();
	}
};

export const fetchModels = async ({
	manufacturer,
	year,
	setModelData,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getModels(manufacturer, year);
		if (response.success) {
			setModelData(response.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (e) {
		console.error(e);
		setStatusError();
	}
};

export const fetchYears = async ({
	manufacturer,
	model,
	variant,
	setYearData,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getYears(manufacturer, model, variant);
		if (response.success) {
			setYearData(response.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (e) {
		console.error(e);
		setStatusError();
	}
};

export const fetchVariants = async ({
	manufacturer,
	model,
	year,
	setVariantData,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getVariants(manufacturer, model, year);
		if (response.success) {
			setVariantData(response.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (e) {
		console.error(e);
		setStatusError();
	}
};
