import React, { useEffect } from "react";
import { SouthAfricanRand } from "../../utils/helpers";
import PageHeader from "../PageHeader";
import useSystemHook from "../../hooks/SystemHook";
import { ScreenSize } from "../../utils/constants";
import {
	Alert,
	Avatar,
	Box,
	Button, Divider,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Typography
} from "@mui/material";

const PaymentAccountCredit = ({
	paymentOrderInfo,
	fullTradeAccount,
	gotoPaymentPage,
	onSubmit
}) => {
	const { screenSize } = useSystemHook();
	const scrollTop = () => {
		window.scrollTo(0, 0);
	};

	useEffect(() => {
		scrollTop();
	}, []);

	return (
		<>
			<PageHeader title="Payment" />
			{screenSize === ScreenSize.Desktop &&
				<Box sx={{ minHeight: "100vh", backgroundColor: "#f7f7f7", padding: "2rem" }}>
					<Grid container justifyContent="center">
						<Grid item xs={12} md={8}>
							<Paper elevation={3} sx={{ padding: "2rem", textAlign: "center" }}>
								<Avatar
									src="https://argusweb.azureedge.net/argus-web/logo.svg"
									alt="Logo"
									sx={{ width: 150, height: 80, margin: "0 auto 1.5rem" }}
								/>

								{!!fullTradeAccount && !!paymentOrderInfo && (
									<>
										<Grid container spacing={4} justifyContent="center">
											<Grid item xs={12} md={6}>
												<Table>
													<TableBody>
														<TableRow>
															<TableCell>
																<Typography variant="body2">Order Total</Typography>
															</TableCell>
															<TableCell>
																<Typography variant="caption">
																	{SouthAfricanRand.format(paymentOrderInfo.amount)}
																</Typography>
															</TableCell>
														</TableRow>
													</TableBody>
												</Table>
											</Grid>
											<Grid item xs={12} md={6}>
												<Table>
													<TableBody>
														<TableRow>
															<TableCell>
																<Typography variant="body2">Available Credit</Typography>
															</TableCell>
															<TableCell>
																<Typography variant="caption">
																	{SouthAfricanRand.format(fullTradeAccount.availableCredit)}
																</Typography>
															</TableCell>
														</TableRow>
														<TableRow>
															<TableCell>
																<Typography variant="body2">Credit Limit</Typography>
															</TableCell>
															<TableCell>
																<Typography variant="caption">
																	{SouthAfricanRand.format(fullTradeAccount.creditLimit)}
																</Typography>
															</TableCell>
														</TableRow>
													</TableBody>
												</Table>
											</Grid>
										</Grid>

										<Divider sx={{ my: 3 }} />

										<Box sx={{ textAlign: "center" }}>
											{fullTradeAccount.availableCredit >= paymentOrderInfo.amount ? (
												<Button
													variant="contained"
													size="small"
													color="error"
													onClick={onSubmit}
													sx={{ width: "50%", margin: "0 auto" }}
												>
													Pay
												</Button>
											) : (
												<>
													<Alert severity="warning" sx={{ marginBottom: "1rem" }}>
														<Typography variant="body2" sx={{ marginBottom: "0.5rem" }}>
															Not enough credit available
														</Typography>
														<Typography variant="caption">
															Your account does not have enough credit to complete this transaction. Please use a different payment method.
														</Typography>
													</Alert>
													<Button
														variant="contained"
														color="error"
														size="small"
														onClick={gotoPaymentPage}
														sx={{ width: "50%", margin: "0 auto" }}
													>
														Choose different payment method
													</Button>
												</>
											)}
										</Box>
									</>
								)}
							</Paper>
						</Grid>
					</Grid>
				</Box>
			}
			{screenSize === ScreenSize.Mobile &&
				<Box sx={{ minHeight: "50vh", backgroundColor: "#f7f7f7", padding: "1rem" }}>
					<Grid container justifyContent="center">
						<Grid item xs={12}>
							<Paper elevation={3} sx={{ padding: "1rem", textAlign: "center" }}>
								<Avatar
									src="https://argusweb.azureedge.net/argus-web/logo.svg"
									alt="Logo"
									sx={{ width: 150, height: 60, margin: "0 auto 1rem" }}
								/>

								{!!fullTradeAccount && !!paymentOrderInfo && (
									<>
										<Grid container justifyContent="center" spacing={2}>
											<Grid item xs={12}>
												<Table size="small">
													<TableBody>
														<TableRow>
															<TableCell>
																<Typography variant="body2" fontWeight="bold">
																	Order Total
																</Typography>
															</TableCell>
															<TableCell>
																<Typography variant="caption">
																	{SouthAfricanRand.format(paymentOrderInfo.amount)}
																</Typography>
															</TableCell>
														</TableRow>
													</TableBody>
												</Table>
											</Grid>
											<Grid item xs={12}>
												<Table size="small">
													<TableBody>
														<TableRow>
															<TableCell>
																<Typography variant="body2" fontWeight="bold">
																	Available Credit
																</Typography>
															</TableCell>
															<TableCell>
																<Typography variant="caption">
																	{SouthAfricanRand.format(fullTradeAccount.availableCredit)}
																</Typography>
															</TableCell>
														</TableRow>
														<TableRow>
															<TableCell>
																<Typography variant="body2" fontWeight="bold">
																	Credit Limit
																</Typography>
															</TableCell>
															<TableCell>
																<Typography variant="caption">
																	{SouthAfricanRand.format(fullTradeAccount.creditLimit)}
																</Typography>
															</TableCell>
														</TableRow>
													</TableBody>
												</Table>
											</Grid>
										</Grid>

										<Divider sx={{ my: 2 }} />

										<Box sx={{ textAlign: "center" }}>
											{fullTradeAccount.availableCredit >= paymentOrderInfo.amount ? (
												<Button
													variant="contained"
													color="error"
													size="small"
													onClick={onSubmit}
													sx={{ width: "100%", marginBottom: 2 }}
												>
													Pay
												</Button>
											) : (
												<>
													<Alert severity="warning" sx={{ marginBottom: 2 }}>
														<Typography variant="body2" sx={{ fontWeight: "bold", marginBottom: 1 }}>
															Not enough credit available
														</Typography>
														<Typography variant="caption">
															Your account does not have enough credit to complete this transaction. Please use a different payment method.
														</Typography>
													</Alert>
													<Button
														variant="contained"
														color="error"
														size="small"
														onClick={gotoPaymentPage}
														sx={{ width: "100%" }}
													>
														Choose different payment method
													</Button>
												</>
											)}
										</Box>
									</>
								)}
							</Paper>
						</Grid>
					</Grid>
				</Box>
			}
		</>
	);
};

export default PaymentAccountCredit;
